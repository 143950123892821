import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { channelByIdAtom, getChannelLogo } from "@sunrise/yallo-channel";
import { atom } from "jotai";

import { playerCurrentEpgItemShownAtom } from "./player-current-epg-item-shown.atom";
import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { PlayerCurrentContent } from "./types";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  schedule: null,
};

/**
 * This does include the content of the player AT SEEK time.
 * Or if we are not seeking, at the current player time.
 * It does an EPG lookup to know what is playing.
 */
export const playerCurrentContentEpgShownAtom = atom<
  Promise<PlayerCurrentContent>
>(async (get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);
  const language = get(currentLanguageAtom);

  if (!playRequest || playRequest.type === "recording") {
    return EMPTY;
  }

  const [channel, epg] = await Promise.all([
    get(channelByIdAtom(playRequest.channelId)),
    get(playerCurrentEpgItemShownAtom),
  ]);

  if (!epg || !channel) {
    return EMPTY;
  }

  return {
    epgId: epg.id,
    channelId: channel.id,
    recordingId: null,
    title: getLocalizedValue(epg.asset.title, language),
    subtitle: getLocalizedValue(epg.asset.subtitle, language),
    channelLogo: getChannelLogo(channel),
    schedule: {
      endTime: new Date(epg.actual_end),
      startTime: new Date(epg.actual_start),
    },
  };
});
