import { type Nullable } from "@sunrise/utils";
import {
  allChannelsAtom,
  lastWatchedChannelIdAtom,
} from "@sunrise/yallo-channel";
import { selectedChannelGroupAtom } from "@sunrise/yallo-channel-group";
import { atom } from "jotai";
import { isNil } from "lodash";

import { selectStartUpChannelId, userAtom } from "./user.service";

/**
 * Returns the channelId we should start playing for the user when the TV-page is loaded.
 * It will go through a set of fallbacks to find a channel to play.
 * - user's last watched channel
 * - user backend settings's startup channel id
 * - first channel in the user's channel list
 * - first channel in the full channel list
 *
 * All channelIds will only ever be returned if they actually exist in the list of channels.
 * If no channel can be found at all, we will return null.
 */
export const startupChannelIdAtom = atom(async (get) => {
  const lwcId = get(lastWatchedChannelIdAtom);
  const channels = (await get(allChannelsAtom)).data;

  function isPresent(id: Nullable<string>): boolean {
    return !!id && channels.result.some((c) => c.id === id);
  }

  if (isPresent(lwcId)) {
    return lwcId;
  }

  const user = (await get(userAtom)).data;
  if (isNil(user)) {
    throw new Error("User does not exist");
  }

  const backendStartupChannelId = selectStartUpChannelId(user);
  if (isPresent(backendStartupChannelId)) {
    return backendStartupChannelId;
  }

  const channelList = await get(selectedChannelGroupAtom);
  const firstChannelInList = channelList?.channels[0]?.channel.id;
  if (isPresent(firstChannelInList)) {
    return firstChannelInList;
  }

  return channels.result[0]?.id ?? null;
});
