import { atom } from "jotai";

import { playerCurrentContentEpgShownAtom } from "./player-current-content-epg-shown.atom";
import { playerCurrentContentRecordingAtom } from "./player-current-content-recordings.atom";
import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { PlayerCurrentContent } from "./types";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  schedule: null,
};

/**
 * Atom to display what is currently playing in the player.
 * Will select data based on the PlayRequest type.
 * This keeps the seek time into account.
 * So if you play out EPG content in the player and seek to the next/previous EPG item on the channel it will change the EPG information we output.
 */
export const playerCurrentContentShownAtom = atom<
  Promise<PlayerCurrentContent>
>(async (get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);

  if (!playRequest) {
    return EMPTY;
  }

  if (playRequest.type === "recording") {
    return get(playerCurrentContentRecordingAtom);
  } else {
    return get(playerCurrentContentEpgShownAtom);
  }
});
