import { isDefined } from "@sunrise/utils";

import { AutoplayError } from "../player.types";

export function isAutoplayError(e: unknown): e is AutoplayError {
  return (
    isDefined(e) &&
    e instanceof Error &&
    "name" in e &&
    e.name === "NotAllowedError"
  );
}
