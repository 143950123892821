import type { Nullable } from "@sunrise/utils";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { selectAtom } from "jotai/utils";

/**
 * Designed so we know how long ago we were last foregrounded / backgrounded.
 * And checking which date is the latest, we can easily determine if we are currently foregrounded or backgrounded.
 *
 * When both are null, it means we have an initial boot. Under which we should be considered foregrounded.
 */
export type ProcessVisibilityState = {
  lastForegroundedAt: Date;
  lastBackgroundedAt: Nullable<Date>;
};

const _processVisibilityAtom = atom<ProcessVisibilityState>({
  lastForegroundedAt: new Date(),
  lastBackgroundedAt: null,
});

export const processVisibilityAtom = atom((get) => {
  get(ensureVisibilityChangeEffect);

  return get(_processVisibilityAtom);
});

/**
 * When subscribed to it will keep the processVisibilityAtom up to date with the current visibility state.
 * It's auto-subscribed to when subscribing to processVisibilityAtom.
 * However it's recommended to subscribe to this effect at the root of the app. Since it's possible only specific pages care about visibility.
 * And subscribing twice is not an issue.
 */
export const ensureVisibilityChangeEffect = atomEffect((get, set) => {
  const handleVisibilityChange = () => {
    const current = get.peek(_processVisibilityAtom);

    if (document.hidden) {
      set(_processVisibilityAtom, {
        ...current,
        lastBackgroundedAt: new Date(),
      });
    } else {
      set(_processVisibilityAtom, {
        ...current,
        lastForegroundedAt: new Date(),
      });
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);

  return () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
});

export const selectProcessIsBackgrounded = selectAtom(
  processVisibilityAtom,
  (state) => {
    if (state.lastBackgroundedAt && state.lastForegroundedAt) {
      return state.lastBackgroundedAt > state.lastForegroundedAt;
    }

    if (state.lastBackgroundedAt) {
      return true;
    }

    return false;
  },
);

export const selectProcessForegroundedAt = selectAtom(
  processVisibilityAtom,
  (state) => state.lastForegroundedAt,
);

export const selectProcessIsForegrounded = selectAtom(
  processVisibilityAtom,
  (state) => {
    if (state.lastBackgroundedAt && state.lastForegroundedAt) {
      return state.lastForegroundedAt > state.lastBackgroundedAt;
    }

    // means there's no foregrounded date yet. But there is a backgrounded. So we are not in the foreground.
    if (state.lastBackgroundedAt) {
      return false;
    }

    return true;
  },
);
