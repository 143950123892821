import { selectLiveStreamingTimeLimitAtom } from "@sunrise/jwt";
import { atom } from "jotai";

import { forceLiveTvTimeLimitReachedAtom } from "./force-live-tv-timelimit-reached.atom";
import { userAtom } from "./user.service";

/*
 * This atom is used to store the information if limit of usage was reached.
 */

const freeUsageLimitReachedSocketAtom = atom<boolean>(false);

freeUsageLimitReachedSocketAtom.debugPrivate = true;

/**
 * To be used when you want an up to date value of the free user streaming time usage.
 * Setting the usage on this will set the value on the freeUsageLimitReachedSocketAtom.
 */

export const freeUsageAtom = atom(
  async (get) => {
    const forced = get(forceLiveTvTimeLimitReachedAtom);

    if (forced) {
      return {
        usage: 0,
        usageLimitReached: true,
        nextResetAt: undefined,
        liveStreamingTimeLimit: null,
      };
    }

    const featureFlagStreamingTimeLimit = get(selectLiveStreamingTimeLimitAtom);

    // Early exit when we know we have no limit.
    if (!featureFlagStreamingTimeLimit) {
      return {
        usage: 0,
        usageLimitReached: false,
        nextResetAt: undefined,
        liveStreamingTimeLimit: null,
      };
    }

    const userQuery = await get(userAtom);
    const userUsageData = userQuery.data?.live_streaming_limit;
    const usageLimitReachedSocket = get(freeUsageLimitReachedSocketAtom);

    const checkIsLimitReached = () => {
      if (
        userUsageData &&
        featureFlagStreamingTimeLimit &&
        userUsageData.streaming_time
      ) {
        return userUsageData.streaming_time >= featureFlagStreamingTimeLimit;
      }

      return usageLimitReachedSocket;
    };

    return {
      usage: userUsageData?.streaming_time ?? 0,
      usageLimitReached: checkIsLimitReached(),
      nextResetAt: userUsageData?.next_reset_at,
      liveStreamingTimeLimit: featureFlagStreamingTimeLimit,
    };
  },
  async (get, set, value: boolean) => {
    set(freeUsageLimitReachedSocketAtom, value);

    // When we receive an update here it means we should now be unsure about the user data.
    const user = await get(userAtom);
    user.refetch();
  },
);
